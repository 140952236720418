<script>
import {mapGetters} from "vuex";
import {GameBlindType, GameStatus} from "../../gameCommon";

export default {
  components:{
    GameCountDown: () => import(/* webpackChunkName: "game-count-down-v2" */ '@/components/Game/CountDown.vue'),
  },
  name: "TablePot",
  data() {
    return {
      totalPot: 0,
    }
  },
  watch: {
    currentRoomState() {
      if (this.currentRoomState <= this.roomState.RiverAction && this.currentRoomState >= this.roomState.FreeFlopAction) {
        this.totalPot = this.pot;
      }
    }
  },
  computed: {
    ...mapGetters('game', ['isRelayGame', "isTournamentGame", 'roomInfo', 'currentRoomState']),
    entryCount(){
      return this.roomInfo?.entryCnt || 0;
    },
    currentEntryCount(){
      return this.entryCount - (this.roomInfo?.curEntryCnt || 0);
    },
    isBreakTimeByCurrentBlind() {
      return this.roomInfo?.blindState?.type === GameBlindType.BREAKTIME;
    },
    isBreakTimeByNextBlind(){
      return this.roomInfo?.nextBlindState?.type === GameBlindType.BREAKTIME;
    },
    smallBlind() {
      return this.roomInfo?.blindState?.sb || 0;
    },
    bigBlind() {
      return this.roomInfo?.blindState?.bb || 0;
    },
    currentBlindLv(){
      return this.roomInfo.blindState?.lv ?? 0;
    },
    nextSmallBlind() {
      return this.roomInfo?.nextBlindState?.sb || 0;
    },
    nextBigBlind() {
      return this.roomInfo?.nextBlindState?.bb || 0;
    },
    blindUpTimestamp(){
      if( !this.isAbleCountDown ){
        return 0;
      }
      return this.roomInfo.tRoundStart + (this.roomInfo.blindUpTime * 1000) || 0;
    },
    totalEntryCnt(){
      return this.roomInfo.entryCnt || 0;
    },
    currentEntryCnt(){
      return this.roomInfo.curEntryCnt || 0;
    },
    pots() {
      return this.roomInfo.pots || [];
    },
    pot() {
      return this.roomInfo.potCash || 0;
    },
    sidePot() {
      if (this.pots.length <= 1) {
        return [];
      }

      return this.pots.slice(1);
    },
    mainPot() {
      if (this.pots.length) {
        return this.pots[0];
      }

      return this.pot;
    },
    isAbleCountDown(){
      return !this.isGameEnd && !this.isGamePause;
    },
    isGameReady(){
      return this.roomInfo.gameStatus === GameStatus.Ready
    },
    isGameEnd(){
      return this.roomInfo.gameStatus === GameStatus.End
    },
    isGamePause(){
      return this.roomInfo.gameStatus === GameStatus.Pause
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.commit('game/SET_REF', {key: 'pot', ref: this.$refs.pot});
    });
  }
}
</script>

<template>
  <div class="pot-container">
    <div ref="pot" class="area_pot">
      <div>POT : {{ pot | formatCash }}</div>
    </div>

    <div class="area_main_pot">
      <div>
        <img src="@/assets/v2/svg/game_chip_green.svg"/>
      </div>
      <div>{{ mainPot | formatCash }}</div>
    </div>

    <!-- TODO: 사이드 팟 (side pot) -->
    <div class="area_side_pot" v-if="sidePot.length">
      <template v-for="(pot, i) in sidePot">
        <div class="side_item" :class="[`pot0${i + 1}`]" :key="i">
          <div class="side_badge">S{{ i + 1 }}</div>
          <div>{{ pot | formatCash }}</div>
        </div>
      </template>
    </div>

    <!-- TODO: 사이드 팟 (side pot) 블라인드 -->
    <div class="area_blind" v-if="isRelayGame">
      블라인드: {{ smallBlind | formatCash }}/{{ bigBlind | formatCash }}<br/>
    </div>
    <div class="area_blind" v-if="isTournamentGame">
      <ul style="list-style: none;">
        <li>블라인드Lv: {{ currentBlindLv }}</li>
        <li v-if="isGameEnd">블라인드: 게임종료</li>
        <li v-else-if="isBreakTimeByCurrentBlind">블라인드: 브레이크타임</li>
        <li v-else>블라인드: {{ smallBlind | formatCash }}/{{ bigBlind | formatCash }}</li>

        <li v-if="isGameEnd">다음 블라인드: 게임종료</li>
        <li v-else-if="isBreakTimeByNextBlind">다음 블라인드: 브레이크타임</li>
        <li v-else>다음 블라인드: {{ nextSmallBlind | formatCash }}/{{ nextBigBlind | formatCash }}</li>

        <li v-if="isGameReady">블라인드 업: 다음 핸드 블라인드업</li>
        <li v-else-if="isGameEnd">블라인드 업: 게임종료</li>
        <li v-else>블라인드 업: <GameCountDown :remainTimestamp="blindUpTimestamp" :formatType="1" /></li>

        <li>엔트리 현황: {{ currentEntryCnt }} / {{ totalEntryCnt }}</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.pot-container {
  position: absolute;
  top: 0;
  left: calc(50% - 6.5rem);
  width: 13rem;
}
</style>